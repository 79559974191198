import React, { Component } from "react";
import Lookup from 'devextreme-react/lookup';
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import { ownStore } from "../../../AppOwnState/ownState";


class FactsDevLookup extends Component {
    constructor(props) {
        super(props);
        this.rowIndex = GetControlPropertyFromStoreOrRefData(props.rowindex, props.refData) ?? "";

        let _listData = ownStore.getState(this.props.listdset);

        let selectValueFromState = props.initialvalue;
        if (this.props.dset && this.props.bind) {
            selectValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." +
                (this.rowIndex === "" ? "" : (this.rowIndex + "."))
                + props.bind + "]", props.refData);
        }
        this.state = {
            selectValue: this.emptyIfValueNotValid(selectValueFromState),
            listData: _listData
        }

    }

    emptyIfValueNotValid(value) {
        if (this.props?.SelectProps?.multiple && !Array.isArray(value)) return [];
        else return value;
    }

    componentDidMount() {
        this.mounted = true;

        this.unsubscribe = ownStore.subscribe((storeInfo) => {
            if (this.mounted) {
                if ([storeInfo.dset, "raw"].includes(this.props.dset) &&
                    (storeInfo.field ?? this.props.bind) === this.props.bind) {

                    if (this.props.dset && this.props.bind) {
                        let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." +
                            (this.rowIndex === "" ? "" : (this.rowIndex + "."))
                            + this.props.bind + "]", this.props.refData);

                        if (this.state.selectValue !== newState) {
                            this.setState({
                                selectValue: this.emptyIfValueNotValid(newState)
                            });

                        }
                    }
                }

                if (storeInfo.dset === "this" || storeInfo.dset === this.props.listdset) {
                    let newList = ownStore.getState(this.props.listdset);
                    let thisListString = JSON.stringify(this.state.listData);
                    let newListString = JSON.stringify(newList);
                    if (thisListString !== newListString) {
                        this.setState({
                            listData: newList
                        });
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
        this.mounted = false;

    }

    async saveCurrentTextToDataset() {
        if (this.props.dset && this.props.bind) {
            if (this.rowIndex !== "") {
                let rowData = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.rowIndex + "]", this.props.refData);

                await ExecuteLayoutEventMethods([
                    {
                        exec: "mergedatasetarray",
                        args: {
                            noprocess: true,
                            dset: this.props.dset,
                            index: parseInt(this.rowIndex),
                            data:
                            {
                                ...rowData,
                                [this.props.bind]: this.state.selectValue
                            }
                        }
                    }]);
            }
            else {
                await ExecuteLayoutEventMethods([

                    {
                        exec: "setdatasetfield",
                        args: {
                            noprocess: true,
                            dset: this.props.dset,
                            fieldname: this.props.bind,
                            data: this.state.selectValue
                        }
                    }
                ]);
            }
        }

    }

    async runCustomOnChangeEvents() {

        await this.saveCurrentTextToDataset();
        await ExecuteLayoutEventMethods(this.props.whenchange, this.state);

    }
    async handleOnChange(e) {
        this.setState({
            selectValue: e.target.value
        }, () => {
            this.runCustomOnChangeEvents();
        });
    }

    async handleValueChange() {
    }


    async handleOnClick(item) {
        await ExecuteLayoutEventMethods(this.props.whenitemclick, item);
    }

    render() {
        return (
            <div>
                <Lookup
                    dataSource={this.state.listData}
                    displayExpr={this.props.textfield}
                    valueExpr={this.props.valuefield}
                    {...this.props}
                    onValueChanged={this.handleValueChange}
                >
                </Lookup>
            </div>
        );
    }
}

export default FactsDevLookup;
