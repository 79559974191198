import React, { Component } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Button, Switch, FormControlLabel, InputAdornment, IconButton, Icon, Typography } from "@mui/material";
import { authServiceInstance, applicationTitle, loginImage, loginTitle, loginDivStyle, loginTextStyle, appTitles, loginImageStyle, loginBoxStyle, setGlobalDrawerWidth } from "../General/globals";
// import store from "../AppRedux/store";
import { showAppBusy, hideAppBusy } from "../AppOwnState";
import { ShowSnackBar } from "../General/globalFunctions";
import { ownStore } from "../AppOwnState/ownState";
import { ExecuteLayoutEventMethods } from "../General/commonFunctions";

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginName: '',
            password: '',
            rememberMe: false,
            pwdPlainText: false
        };
        document.title = applicationTitle;
        setGlobalDrawerWidth(0);
    }

    handleInputChange(e) {
        let stateValue = e.target.value;
        if (e.target.type === "checkbox") {
            stateValue = e.target.checked;
        }
        this.setState({
            [e.target.id]: stateValue
        });
    }


    async doSignIn(e) {
        try {
            e.preventDefault();
            ownStore.dispatch(showAppBusy());
            await authServiceInstance.login(this.state.loginName, this.state.password, this.state.rememberMe);
            let authOK = await authServiceInstance.verifyLogin();
            if (authOK?.status) {
                if (authOK?.doNext) {
                    ownStore.dispatch(hideAppBusy());
                    try {
                        let authEvents = JSON.parse(authOK.doNext);
                        await ExecuteLayoutEventMethods(authEvents);
                    }
                    catch { }
                    return;
                }
                authServiceInstance.continueLogin();
                return;
            }
        }
        catch (ex) {

            console.error(ex);
        }
        ownStore.dispatch(hideAppBusy()); //Hide loader if the authentication failed with an errorx

        ShowSnackBar("error", 'Login Failed!');
    }



    render() {
        return (
            <form onSubmit={this.doSignIn.bind(this)}>
                <Card className="login-card" variant="outlined" style={loginBoxStyle} >
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="login-head" style={loginDivStyle}  >
                                {loginImage &&
                                    <img style={{ height: "100px", ...loginImageStyle }} src={loginImage} alt="Logo" />}
                                {loginTitle &&
                                    <h2 style={loginTextStyle}>{loginTitle}</h2>}
                            </Grid>
                            <Grid item xs={12} >
                                <TextField id="loginName" label={appTitles?.loginname ?? "User Name"} variant="outlined" size="small"
                                    autoComplete="off"
                                    onChange={this.handleInputChange.bind(this)}
                                    InputProps={{ style: { backgroundColor: "white" } }}
                                    value={this.state.loginName} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="password" label={appTitles?.password ?? "Password"} variant="outlined" size="small"
                                    autoComplete="off"

                                    onChange={this.handleInputChange.bind(this)}
                                    value={this.state.password}
                                    type={this.state.pwdPlainText ? "text" : "password"}
                                    fullWidth
                                    InputProps={{
                                        style: { backgroundColor: "white" },
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => this.setState({ pwdPlainText: !this.state.pwdPlainText })}>
                                                <Icon>{this.state.pwdPlainText ? "visibility_off" : "visibility"}</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={
                                    <Switch id="rememberMe" checked={this.state.rememberMe}
                                        onChange={this.handleInputChange.bind(this)} />
                                }

                                    label={<Typography style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{appTitles?.rememberme ?? "Remember Me"}</Typography>}
                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Button type="submit" variant="contained" size="medium" color="primary" fullWidth>
                                    {appTitles?.loginbutton ?? "Sign In"}</Button>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </form>
        );
    }

}

export default LoginComponent; 